.indicatorWrapper {
  position: relative;
  height: 100%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 22px;
    left: 50%;
    width: 0;
    height: calc(100% - 22px);
    border: 1px solid #0d7680;
    transform: translateX(-50%);
  }

  &.last::after {
    display: none;
  }
}

.indicatorBox {
  width: 22px;
  height: 22px;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid #0d7680;
  background: #ffffff;
  box-sizing: border-box;
  position: absolute;
  left: calc(50% - 11px);
}

.indicationTime-4 .indicatorBox::after,
.indicatorBox.checked::after {
  content: url('../../../../assets/icons/icon-checked.svg');
  position: absolute;
  top: -1px;
  left: 1px;
  padding: 1px;
  width: 15px;
  height: 11px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}

.indicationTime-4 .indicatorBox::after,
.indicationTime-4 .indicatorBox.checked::after {
  color: #0d7680;
}

.indicator {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.indicationTime-0 .indicator {
  background: #ffffff;
}

.indicationTime-1 .indicator {
  background: #0d7680;
}

.indicationTime-2 {
  &.indicatorWrapper::after {
    border-color: #ffb549;
  }

  .indicatorBox {
    border-color: #ffb549;
  }

  .indicator {
    background: #ffb549;
  }
}

.indicationTime-3 {
  &.indicatorWrapper::after {
    border-color: #9e2f50;
  }

  .indicatorBox {
    border-color: #9e2f50;
  }

  .indicator {
    background: #9e2f50;
  }
}

.indicationTime-4 {
  .indicatorBox,
  .indicator {
    background: #bfdbde;
  }
}

.indicationTime-5 {
  .indicatorBox,
  .indicator {
    background: #cccccc;
  }

  .indicatorBox::after {
    content: url('../../../../assets/icons/step-missing.svg');
    position: absolute;
    top: -3px;
    left: -2px;
    padding: 1px 0;
    width: 22px;
    height: 22px;
    color: #585858;
    font-size: 16px;
    text-align: center;
  }

  .indicatorBox,
  &.indicatorWrapper::after {
    border-color: #7d7d7d;
  }
}
