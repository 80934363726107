.accordionSection {
  p {
    font-size: 16px !important;
    margin-bottom: 15px !important;
  }

  .mbaAccordion {
    font-family: MetricWeb, sans-serif;
    padding: 30px 0;
  }

  .mbaAccordion:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
}
