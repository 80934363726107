@font-face {
  font-family: 'FinancierDisplayWeb';
  font-weight: 400;
  font-display: swap;
  src: local('FinancierDisplayWeb Regular'), local('FinancierDisplayWeb-Regular'),
    url('../fonts/FinancierDisplayWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'FinancierDisplayWeb';
  font-weight: 700;
  font-display: swap;
  src: local('FinancierDisplayWeb Bold'), local('FinancierDisplayWeb-Bold'),
    url('../fonts/FinancierDisplayWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'MetricWeb';
  font-weight: 400;
  font-display: swap;
  src: local('MetricWeb Regular'), local('MetricWeb-Regular'), url('../fonts/MetricWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'MetricWeb';
  font-weight: 600;
  font-display: swap;
  src: local('MetricWeb Bold'), local('MetricWeb-Bold'), url('../fonts/MetricWeb-Semibold.woff2') format('woff2');
}

$financier-display: 'FinancierDisplayWeb', serif;
$metric: 'MetricWeb', sans-serif;
