.actionsCell button {
  margin: 0 2px;
}

.checkboxCell {
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 10px !important;

  label,
  div {
    margin: 0 !important;
  }
}

.link {
  color: #0d7680;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-color: #cfe4e6;
}

.link:hover {
  text-decoration-color: #9ec8cc;
}
