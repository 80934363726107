.radioInputs label {
  display: inline-block !important;
  margin-right: 50px !important;
}

.actionsWrapper {
  text-align: right;

  > div {
    margin: 10px;
  }
}
