.mba-full-width {
  width: 100%;
}

.mba-center--vertically {
  display: inline-flex !important;
  align-items: center !important;
}

.mba-space-between {
  display: flex;
  justify-content: space-between;
}

//Padding
.mba-no-padding {
  padding: 0 !important;
}

.mba-padding-x-28 {
  padding: 0 28px !important;
}

//Margin
.mba-no-margin {
  margin: 0 !important;
}

.mba-margin-top-10 {
  margin-top: 10px !important;
}

.mba-margin-top-20 {
  margin-top: 20px !important;
}

.mba-margin-top-30 {
  margin-top: 30px !important;
}

.mba-margin-top-40 {
  margin-top: 40px !important;
}

.mba-margin-bottom-10 {
  margin-bottom: 10px !important;
}

.mba-margin-bottom-15 {
  margin-bottom: 15px !important;
}

.mba-margin-bottom-20 {
  margin-bottom: 20px !important;
}

.mba-margin-bottom-30 {
  margin-bottom: 30px !important;
}

.mba-margin-right-10 {
  margin-right: 10px !important;
}

.mba-margin-x-20 {
  margin: 0 20px !important;
}

.mba-margin-y-20 {
  margin: 20px 0 !important;
}

.mba-text--center {
  text-align: center;
}

.mba-text--right {
  text-align: right !important;
}

.mba-text--semi-bold {
  font-weight: 500 !important;
}

.mba-text--accent {
  color: #0d7680 !important;
}

.mba-text--b2b {
  color: #0f5499 !important;
}

.mba-text--error {
  color: #cc0000 !important;
}

.mba-font--14 {
  font-size: 14px !important;
}

.mba-font--16 {
  font-size: 16px !important;
}

.mba-font--20 {
  font-size: 20px !important;
}

.mba-font-weight-600 {
  font-weight: 600 !important;
}

.mba-button--link {
  padding: 0;
  color: #0d7680;
  font-family: MetricWeb, sans-serif;
  font-size: 16px;
  text-decoration-color: #cfe4e6;
  text-decoration-thickness: 0.25ex;
  text-decoration-line: underline;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #08474d;
    text-decoration-color: #9ec8cc;
  }

  &-b2b {
    color: #0f5499;
  }

  &-b2b:hover {
    color: darken(#0f5499, 10%);
    text-decoration-color: lighten(#0f5499, 30%);
  }
}

.mba-timeline-button--link {
  padding: 0;
  color: #0d7680;
  font-family: MetricWeb, sans-serif;
  font-size: 18px;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
}

.mba-width--full {
  max-width: 100% !important;
  width: 100%;
}

.mba-text-capitalize {
  text-transform: capitalize !important;
}

.mba-text--bold {
  font-weight: 700 !important;
}

.flexColumnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
