.successMessage {
  width: 300px;
  height: 70px;
  animation-duration: 1.25s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    transform: translateX(150%);
  }

  to {
    transform: translateX(0%);
  }
}

.accountSetup {
  position: fixed !important;
  top: 80px;
  right: 0;
  transition: all 0.3s;
  animation: none !important;
  z-index: 1;
  opacity: 0;
  @media screen and (max-width: 739px) {
    display: none !important;
  }
}

.slideSuccess {
  transform: translateX(-50%);
  opacity: 1;
}
