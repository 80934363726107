.timeline {
  width: 100%;
  margin: 40px auto 20px;
  text-align: left;
}

.tickIcon {
  background-image: url('../../../assets/icons/icon-checked.svg');
  display: inline-block;
  position: relative;
  top: 1px;
  width: 15px;
  height: 11px;
  margin-left: 10px;
}

.shortenedAlumniLetter {
  height: 100px;
  overflow: hidden;
}
