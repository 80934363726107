.accordionSectionContent {
  position: relative;
  margin-bottom: 30px;

  .accordionSectionDescription {
    border-bottom: 1px solid #cccccc;
    padding: 15px 0 20px;
  }

  .questionBtn {
    font-size: 18px !important;
    color: #0d7680 !important;
    margin: 0 !important;
    display: inline-flex;
    position: relative;
    align-items: center;
    border: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
    text-align: left;

    &.icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      content: url('https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1:arrow-down?source=build-service&tint=%230D7680,%230D7680&format=svg');
      margin-left: 15px;
    }

    &::after {
      display: inline-block;
      background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1:arrow-down?source=build-service&tint=%230D7680,%230D7680&format=svg');
      margin-left: 15px;
      flex-shrink: 0;
      width: 2rem;
      height: 2rem;
      content: '';
      background-repeat: no-repeat;
      background-size: 2rem;
    }

    &.itemSelected::after {
      transform: rotate(180deg);
    }
  }
}
