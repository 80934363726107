.closeButton {
  cursor: pointer;
}

.closeButton--success {
  background-image: url(https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1%3Across?source=mba-rankings&tint=%2300572c) !important;
}

.main {
  margin-top: -5px !important;
  padding-left: 20px;
  font-size: 16px !important;
}
