.menuItemWrapper {
  margin-left: auto;

  button {
    cursor: pointer;
  }

  ul {
    pointer-events: all !important;
  }
}

.switchSchoolButton {
  padding: 10px 20px;
  font-family: MetricWeb, sans-serif;
  font-size: 16px;
  text-align: right;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.currentSchool {
  button {
    font-weight: 700;
  }
}
