.container {
  padding: 30px;
  background-color: #ffffff;

  .content {
    max-width: 80ch;
  }

  ul {
    margin: 0;
  }
}
