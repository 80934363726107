// Headings
.mba-heading--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 475px) {
    flex-direction: row;
  }
}

.mba-heading--b2b {
  color: #0f5499;
  font-size: 24px;
  font-weight: 500;
}

.mba-heading--table {
  margin: 20px 0 10px !important;
}

.mba-heading--back {
  line-height: 32px;
  margin: 30px 0 10px !important;
  color: #262a33;
  font-size: 32px;
  font-weight: 600;
}

.mba-heading--title {
  margin: 0 !important;
  font-size: 32px !important;

  @media screen and (min-width: 740px) {
    font-size: 40px !important;
  }
}

.mba-heading--title-2 {
  margin: 0 !important;
  font-size: 26px !important;

  @media screen and (min-width: 740px) {
    font-size: 30px !important;
  }
}

.mba-page-heading-title {
  font-style: normal !important;
  font-size: 40px !important;
  line-height: 40px !important;
  color: #000000 !important;
}

.mba-section-heading-title {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 28px !important;
  line-height: 31px !important;
  color: #000000 !important;
}

.mba-section-title {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 26px !important;
  line-height: 25px !important;
  color: #000000 !important;

  .link-title {
    color: #0f5499 !important;
  }
}

.mba-back-arrow {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

// Grid columns
.mba-column-group {
  margin-top: 30px;

  &--compact {
    margin: 30px -20px 0;
  }
}

.mba-details-column {
  @media screen and (min-width: 740px) {
    padding: 0 50px !important;
    border-left: 1px solid #cccccc;

    &--single {
      padding: 0 !important;
      border-left: 0;
    }

    &--first {
      padding-right: 50px;
      padding-left: 0 !important;
      border-left: 0;
    }

    &--last {
      padding-right: 0 !important;
    }
  }
}

// Action buttons
.mba-actions {
  &--top-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  > div:not(:last-child) {
    margin-right: 10px;
  }
}

// Tables
@media screen and (min-width: 740px) {
  .mba-table-wrapper {
    display: flex;
    min-height: 550px;

    .mba-table-container {
      position: relative;
      width: 78%;
    }

    .mba-table-filters {
      width: 22%;
      margin-right: 30px;
      padding-right: 20px;
    }
  }
}

// Separators
.mba-separator {
  margin: 45px 0 20px;
  border: 0;
  border-bottom: 1px solid #cccccc;
}

.mba-background--white {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
}

// header menu
.header-nav-wrapper {
  display: flex;
  justify-content: space-between;

  a {
    cursor: pointer;
  }
}

.faq-search {
  min-width: 200px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

// Page section

.section {
  margin: 30px 0 20px !important;

  .title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 25px !important;
    color: #000000 !important;

    .link-title {
      color: #0f5499 !important;
    }
  }

  .subTitle {
    font-size: 16px !important;
    line-height: 25px !important;
    color: #000000 !important;
    font-weight: 600 !important;
  }
}
