.container {
  margin-left: 20px;
  max-width: 13rem;
}

.selectWrapper {
  position: relative;
  left: -65px;
  width: 180%;
  top: 10px;
  margin-bottom: -15px;
}

.text {
  color: #0d7680;
  cursor: pointer;
  font-weight: 500;
}

.text:hover {
  cursor: pointer;
}

.actionBtns {
  display: flex;

  span {
    margin-right: 15px;
    cursor: pointer;
  }

  span:first-child {
    color: #0d7680;
    font-weight: 500;
  }
}
