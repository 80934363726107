.rankingHeader {
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none !important;
  }
}

.toTimelineLink {
  text-decoration: none !important;
}

.dataWrapper {
  margin: 10px 0 5px;
}

.colorRed {
  color: rgb(255, 0, 0);
}
