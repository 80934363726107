.iconWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: none;
  outline: none;
  border: 0;
  cursor: pointer;

  svg {
    margin-top: 2px;
  }

  svg g {
    fill: #0d7680;
    transition: fill 0.2s;
  }

  &:hover svg g {
    fill: #095259;
  }
}
