.wrapper {
  position: relative;
  height: 100%;
}

.userIcon {
  font-family: MetricWeb, sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  color: #ffffff;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: 58px;

  @media screen and (max-width: 739px) {
    width: 100%;
    margin-left: 15px;
    color: #000000;
    text-align: left;
  }
}

.logoutButton {
  padding: 10px 20px;
  font-family: MetricWeb, sans-serif;
  font-size: 16px;
  text-align: right;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 740px) {
  .logoutButton {
    display: block;
    width: 100%;
    padding: 0;
    color: #ffffff;
    text-align: left;
  }

  .logoutButton:hover {
    text-decoration: none;
  }

  .dropdown {
    position: absolute;
    width: 200px;
    top: 51px;
    right: -15px;
    z-index: 10;
  }

  .dropdownContent {
    padding: 10px 20px;
    background-color: #262a33;

    li {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .logoutButton,
    li {
      text-transform: uppercase;
      font-weight: 600;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: border-bottom-color 0.2s;
    }

    li:hover {
      text-decoration: none;
      border-bottom-color: #ffffff;
    }

    li a {
      display: block !important;
      padding: 0 !important;
      text-decoration: none !important;
    }
  }
}
