.header {
  display: flex;
}

.content {
  padding: 20px;
  margin-top: 0 !important;

  hr {
    margin-top: 20px;
  }
}

.datesCouple {
  display: flex;
  justify-content: space-between;
}

.buttonWrapper {
  text-align: center;
}

.icon {
  width: 21px;
  height: 21px;
  display: inline-block;
  margin-top: 6px;
  margin-left: 11px;
}

.icon::after {
  content: url('../../../../assets/icons/download.svg');
}

.icon:hover {
  background-color: transparent !important;
  cursor: pointer;
}
