.container {
  background-color: #ffffff;
  padding: 30px;
}

.content {
  max-width: 100% !important;
  width: 100%;
}

.title {
  font-size: larger !important;
}

.actionButtons {
  text-align: end;
}
