.dropzoneWrapper {
  position: relative;
  height: 100px;
  margin: 10px 0 35px;
  border: 1px dashed #262a33;
}

.dropzone {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.label {
  color: #262a33;
  font-weight: 500;
}

.dropzoneInput {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.linkButton {
  color: #0d7680;
  font-weight: 500;
  border-bottom: 1px solid #0d7680;
}

.invalidNameMessage {
  color: #ff0000;
  margin-bottom: 30px;
}
