.mba-auth-form-wrapper {
  margin: 10px auto 30px;

  @media screen and (min-width: 740px) {
    margin-top: 150px;
  }
}

.mba-auth-form {
  padding: 40px !important;
  background: #ffffff;
  border-radius: 3px;
  box-sizing: content-box;
}

.mba-auth-heading {
  padding-top: 10px;
  line-height: 28px !important;
  font-size: 24px !important;
}

.mba-auth-link {
  display: inline-block;
  margin-top: 15px;
  font-size: 16px;
}

.mba-auth-submit-button {
  padding-top: 5px;
}
