.button[class~='o-buttons--basic'] {
  border: 0 !important;
}

.buttonWrapper {
  position: relative;
  display: inline-flex;

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.fullWidth .button {
    width: 100%;
  }

  &.loading .button {
    background-image: none;
  }

  &.loading .buttonText {
    opacity: 0;
  }
}

.loader {
  position: absolute;
  top: calc(20%);
  left: calc(42%);
  transform: translate(-50%, -50%);

  &.loaderGreen {
    border-color: rgba(#0d7683, 0.25) !important;
    border-top-color: #0d7683 !important;
  }

  &.big {
    top: calc(20%);
    left: calc(40%);
  }
}

$color-danger: #990f3d;

.dangerPrimary {
  background-color: $color-danger !important;

  &:focus,
  &:hover {
    background-color: darken($color-danger, 10%) !important;
  }
}

.dangerOutlined {
  color: $color-danger !important;
  border-color: $color-danger !important;

  &:focus,
  &:hover {
    color: $color-danger !important;
    border-color: $color-danger !important;
    background-color: rgba($color-danger, 0.1) !important;
  }
}

.rightIcon {
  background-position-x: right !important;
  padding-left: 8px !important;
  padding-right: 24px !important;
}

.rightIconBig {
  background-position-x: right !important;
  padding-left: 20px !important;
  padding-right: 40px !important;
}
